section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

.home {
  display: flex;
  align-items: center;
  padding: 0 9%;
}

.home::before {
  position: absolute;
  content: "";
  top: 0;
  border-radius: 50%;
  border: 1px solid var(--main-color);
  right: 7%;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 300px;
  z-index: -1;
  background: url("/public/assets/ankur.jpg") no-repeat;
  background-position: center;
  /* background-size: 40rem; */
  background-size: 320px;
  transform: scale(1.3);
}

.home-content {
  max-width: 70rem;
}

.home-content h1 {
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.3;
  position: relative;
  display: inline-block;
}

.home-content .text-animate {
  position: relative;
  width: 32.8rem;
}

.home-content .text-animate h3 {
  font-size: 3.2rem;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 0.7px var(--main-color);
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-position: 0 0;
  animation: homeBgText 6s linear infinite;
  animation-delay: 2s;
}

.home-content .text-animate h3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  /* background-color: red; */
  border-right: 2px solid var(--main-color);
  z-index: -1;
  animation: moveCursor 6s linear infinite;
  animation-delay: 2s;
}

.home-content p {
  font-size: 1.6rem;
  margin: 2rem 0 4rem;
  position: relative;
}

.btn-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 34.5rem;
  height: 5rem;
}

.btn-box .btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 100%;
  background: var(--main-color);
  border: 0.2rem solid var(--main-color);
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: var(--bg-color);
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.btn-box .btn:hover {
  color: var(--main-color);
}
.btn-box .btn:nth-child(2) {
  background: transparent;
  color: var(--main-color);
}
.btn-box .btn:nth-child(2):hover {
  color: var(--bg-color);
}
.btn-box .btn:nth-child(2)::before {
  background: var(--main-color);
}

.btn-box .btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: var(--bg-color);
  transition: 0.5s;
  z-index: -1;
}

.btn-box .btn:hover::before {
  width: 100%;
}

.home-sci {
  position: absolute;
  bottom: 4rem;
  width: 170px;
  display: flex;
  justify-content: space-between;
}

.home-sci a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 0.2rem solid var(--main-color);
  border-radius: 50%;
  font-size: 20px;
  color: var(--main-color);
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
}

.home-sci a::before {
  position: absolute;
  content: "";
  width: 0%;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--main-color);
  transition: 0.5s;
  z-index: -1;
}

.home-sci a:hover {
  color: var(--bg-color);
}

.home-sci a:hover::before {
  width: 100%;
}

/* About component */

@media (max-width: 990px) {
  section {
    padding: 10rem 4% 2rem;
  }

  .home {
    padding: 0 4%;
  }
  .home::before {
    display: none;
  }
  .home-img {
    display: none !important;
  }
}

@media (max-width: 520px) {
  .home-content h1 {
    display: flex;
    flex-direction: column;
  }
  .home-sci {
    width: 160px;
  }
  .home-sci a {
    width: 38px;
    height: 38px;
  }
}

@media (max-width: 462px) {
  .home-content h1 {
    font-size: 5.2rem;
  }
}

@media (max-width: 371px) {
  .home {
    justify-content: center;
  }
  .home-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .home-content h1 {
    font-size: 5rem;
  }
}

@keyframes homeBgText {
  0%,
  10%,
  100% {
    background-position: -33rem 0;
  }
  65%,
  85% {
    background-position: 0 0;
  }
}

@keyframes moveCursor {
  0%,
  10%,
  100% {
    width: 0%;
  }
  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }
  75%,
  81% {
    opacity: 0;
  }
}
