.skills {
  min-height: auto;
  padding-bottom: 7rem;
  background: var(--second-bg-color);
}

.skills .skills-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
}

.skills-row .skills-column {
  flex: 1 1 40rem;
}

.skills-column .title {
  font-size: 2.5rem;
  margin: 0 0 1.5rem;
  position: relative;
  display: inline-block;
}

.skills-box .skills-content {
  position: relative;
  border: 0.2rem solid var(--main-color);
  border-radius: 0.6rem;
  padding: 0.5rem 1.5rem;
  overflow: hidden;
  z-index: 1;
}

.skills-box .skills-content::before {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--bg-color);
  z-index: -1;
  transition: 0.3s;
}

.skills-box .skills-content:hover::before {
  width: 100%;
}

.skills-content .progress {
  padding: 1rem 0;
}

.skills-content .progress h3 {
  font-size: 1.7rem;
  display: flex;
  justify-content: space-between;
}

.skills-content .progress h3 span {
  color: var(--text-color);
}

.skills-content .progress .bar {
  height: 2.5rem;
  border-radius: 0.6rem;
  border: 0.2rem solid var(--main-color);
  padding: 0.5rem;
  margin: 1rem 0;
}

.skills-content .progress .bar span {
  display: block;
  height: 100%;
  border-radius: 0.3rem;
  background: var(--main-color);
}
