.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  transition: 0.3s;
}

.header.sticky {
  background-color: var(--bg-color);
}

.logo {
  position: relative;
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--text-color);
}

.navbar {
  position: relative;
}
.navbar a {
  font-size: 1.7rem;
  color: var(--text-color);
  font-weight: 500;
  margin-left: 3.5rem;
  transition: 0.3s;
}

.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}

#menu-icon {
  position: relative;
  font-size: 2.5rem;
  color: var(--text-color);
  cursor: pointer;
  display: none;
}

/* .logo .animate,
.navbar .animate,
#menu-icon .animate {
  animation: showRight 2s ease forwards;
  animation-delay: calc(0.3s * var(--i));
} */
@media (max-width: 990px) {
  .header {
    padding: 2rem 4%;
  }
}

@media (max-width: 768px) {
  .header {
    background: var(--bg-color);
  }

  #menu-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: -100%;
    padding: 1rem 4%;
    width: 100%;
    background: var(--bg-color);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    transition: 0.25s ease;
    transition-delay: 0.25s;
  }
  .navbar.active {
    left: 0;
    /* display: block; */
    transition-delay: 0s;
  }
  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  }
}
