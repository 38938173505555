.education {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: auto;
  padding-bottom: 5rem;
}
.education .education-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5rem;
}

.education-row .education-column {
  flex: 1 1 40rem;
}

.education-column .title {
  font-size: 2.5rem;
  margin: 0 0 1.5rem 2rem;
  position: relative;
  display: inline-block;
}

.education-box {
  position: relative;
}

.education-column .education-box {
  border-left: 0.2rem solid var(--main-color);
}

.education-box .education-content {
  position: relative;
  padding-left: 2rem;
}

.education-box .education-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: -1.1rem;
  width: 2rem;
  height: 2rem;
  background-color: var(--main-color);
  border-radius: 50%;
}

.education-content .content {
  position: relative;
  padding: 1.5rem;
  border: 0.2rem solid var(--main-color);
  border-radius: 0.6rem;
  margin-bottom: 2rem;
  transition: 0.3s;
  z-index: 1;
  overflow: hidden;
}

.education-content .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: var(--second-bg-color);
  transition: 0.5s;
  z-index: -1;
}

.education-content .content:hover::before {
  width: 100%;
}

.education-content .content .year {
  font-size: 1.5rem;
  color: var(--main-color);
  padding-bottom: 0.5rem;
}

.education-content .content .year i {
  padding-right: 0.5rem;
}

.education-content .content h3 {
  font-size: 2rem;
}

.education-content .content p {
  font-size: 1.6rem;
  padding: 0.5rem;
}

@media (max-width: 462px) {
  .education {
    padding: 10rem 4% 5rem 5%;
  }
}
