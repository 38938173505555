.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 6rem;
  background-color: var(--second-bg-color);
}

span {
  color: var(--main-color);
}

.about-img {
  position: relative;
  width: 25rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.about-img img {
  width: 90%;
  border-radius: 50%;
  border: 0.2rem solid var(--main-color);
}

.about-img .circle-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 50%;
  border-left: 0.3rem solid var(--main-color);
  border-right: 0.3rem solid var(--main-color);
  border-bottom: 0.3rem solid var(--second-bg-color);
  border-top: 0.3rem solid var(--second-bg-color);
  animation: rotateRound 8s linear infinite;
}

.about-content {
  text-align: center;
}

.about-content h3 {
  font-size: 2.6rem;
  position: relative;
  display: inline-block;
}

.about-content p {
  position: relative;
  font-size: 1.6rem;
  margin: 2rem 0 3rem;
}

.btn-box.btns {
  display: inline-block;
  width: 15rem;
}

.btn-box.btns a::before {
  background: var(--second-bg-color);
}

@keyframes rotateRound {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
