@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg-color: #081b29;
  --second-bg-color: #112e42;
  --text-color: #ededed;
  --main-color: #00abf0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

.heading {
  font-size: 5rem;
  margin-bottom: 3rem;
  position: relative;
  text-align: center;
}

.inline_heading {
  display: inline-block;
  left: 50%;
  transform: translate(-50%);
}

.logo .animate,
.navbar .animate,
#menu-icon .animate {
  animation: showRight 2s ease forwards;
  animation-delay: calc(0.1s * var(--i));
}

@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 520px) {
  html {
    font-size: 50%;
  }
}

.animate {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: var(--bg-color);
  z-index: 98;
}

.animate.home-img {
  width: 50%;
}

.education-box .animate {
  width: 105%;
}

.logo .animate,
.navbar .animate,
#menu-icon .animate,
.home.show-animate .animate {
  animation: showRight 2s ease forwards;
  animation-delay: calc(0.2s * var(--i));
}

.animate.scroll {
  transition: 1s ease;
  transition-delay: calc(0.2s / var(--i));
  animation: none;
}

section:nth-child(odd) .animate.scroll {
  background: var(--second-bg-color);
}

.about.show-animate .animate.scroll,
.contact.show-animate .animate.scroll,
.education.show-animate .animate.scroll,
.skills.show-animate .animate.scroll {
  transition-delay: calc(0.2s * var(--i));
  width: 0;
}

@keyframes showRight {
  100% {
    width: 0;
  }
}
