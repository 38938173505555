.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 9%;
  background-color: var(--second-bg-color);
}

.footer-text p {
  font-size: 1.6rem;
}
.footer-iconTop a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background: var(--main-color);
  border: 0.2rem solid var(--main-color);
  border-radius: 0.6rem;
  z-index: 1;
  overflow: hidden;
}

.footer-iconTop a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: var(--second-bg-color);
  z-index: -1;
  transition: 0.5s;
}

.footer-iconTop a:hover::before {
  width: 100%;
}
.footer-iconTop a i {
  font-size: 2.4rem;
  color: var(--bg-color);
}

.footer-iconTop a:hover i {
  color: var(--main-color);
}

@media (max-width: 990px) {
  .footer {
    padding: 2rem 4%;
  }
}

@media (max-width: 462px) {
  .footer {
    flex-direction: column-reverse;
  }
  .footer p {
    margin-top: 2rem;
    text-align: center;
  }
}
